<template>
  <div>
    <HeroSection></HeroSection>
    <FeatureSection></FeatureSection>
    <PartnerSection></PartnerSection>
    <FAQSection></FAQSection>
    <ContactSection></ContactSection>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection'
import FeatureSection from "@/components/FeatureSection";
import FAQSection from '@/components/FAQSection'
import ContactSection from "@/components/ContactSection";
import PartnerSection from "@/components/PartnerSection"
export default {
  name: 'App',
  components: {
    ContactSection,
    FeatureSection,
    HeroSection,
    PartnerSection,
    FAQSection
  }
}
</script>

<style>

</style>
