<template>
  <div class="relative overflow-hidden" id="home">
    <Popover as="header" class="relative">
      <div class="bg-indigo-900 pt-8">
        <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-6" aria-label="Global">
          <div class="flex flex-1 items-center">
            <div class="flex w-full justify-between md:w-auto">
              <a href="#">
                <div class="border border-transparent rounded-xl py-2 text-2xl text-white">
                  <span class="flex items-center font-bold h-8 w-auto sm:h-8">
                  <img class="mr-2 h-12 w-auto sm:h-12" src="../assets/logo.png" alt="" />
                    Decentral Store
                  </span>
                </div>
              </a>
              <div class="-mr-2 mb-4 flex items-center md:hidden">
                <PopoverButton class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="hidden space-x-8 md:ml-10 md:flex">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-base font-medium text-white hover:text-gray-300">{{ item.name }}</a>
            </div>
          </div>
          <div class="hidden md:flex md:items-center md:space-x-6">
            <a target="_blank" href="https://decentralstoreapp.web.app/" class="inline-flex items-center rounded-md border border-transparent bg-zinc-800 px-4 py-2 text-base font-medium text-white hover:bg-zinc-900">Launch App</a>
          </div>
        </nav>
      </div>

      <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
        <PopoverPanel focus class="absolute inset-x-0 top-0 z-10 origin-top transform p-2 transition md:hidden">
          <div class="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
            <div class="flex items-center justify-between px-5 pt-4">
              <div>
                <img class="h-8 w-auto" src="../assets/logo.png" alt="" />
              </div>
              <div class="-mr-2">
                <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-900">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="pt-5 pb-6">
              <div class="space-y-1 px-2">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>

    <main>
      <div class="min-h-screen flex items-center bg-indigo-900 lg:overflow-hidden lg:pt-8 lg:pb-14">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
              <div class="lg:py-24">
                <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl lg:mt-6 xl:text-5xl">
<!--                  <span class="block text-indigo-300">Web3 Toolkit + Platform</span>-->
                  <span class="block">Web3 Toolkit + Platform for Commercial Brands</span>
                </h1>

                <div class="mt-6">
                  <span class="text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Toolkit for commercial brands building customer loyalty program with NFT and DAO integration</span>
                  <div class="md:hidden mt-6 md:flex md:items-center md:space-x-6">
                    <a target="_blank" href="https://decentralstoreapp.web.app/" class="inline-flex h-12 sm:h-16 items-center rounded-md border border-transparent bg-zinc-800 px-8 py-2 text-xl font-medium text-white hover:bg-zinc-900">Launch App</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 -mb-16 sm:-mb-48 lg:relative lg:m-0">
              <div class="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
                <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
                <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- More main page content here... -->
    </main>
  </div>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Home', href: '#home' },
  { name: 'Features', href: '#feature' },
  { name: 'FAQs', href: '#faq' },
  { name: 'Contact', href: '#contact' },
]
</script>