<template>
  <div class="bg-gray-200" id="faq">
    <div class="mx-auto max-w-7xl py-12 px-6 sm:py-16 lg:px-8">
      <div class="mx-auto max-w-3xl divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Frequently asked questions</h2>
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-400">
                <span class="font-medium text-gray-900">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <div class="mt-4 text-base text-gray-500" v-for="(answer, index) in faq.answer" :key="index">
                {{ answer }}
              </div>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: "What’s the difference between testnet and mainnet?",
    answer: [
        "Mainnet is the production network while testnet is the sandbox environment for commercial brands and customers to experience the platform’s features."
    ]
  },
  {
    question: "What's the tokens used for mainnet and testnet respectively",
    answer: [
        "For mainnet, USDC is used for customers paying to commercial brands and matic is the token required to pay for the gas fee for every blockchain transaction.",
        "For testnet, mock stablecoin and testnet gas token will be used which could be retrieved from the faucet in the testnet site."]
  },
  {
    question: "Which blockchain network Decentral Store platform is built on?",
    answer: [
      "Polygon"
    ],
  },
  {
    question: "Prerequisites to use the platform?",
    answer: [
        "Crypto wallet should be installed and created with an account to send out blockchain transactions. Recommend use of metamask wallet.",
        "Top up with matic token to pay for blockchain transaction fees. (0.5 matic token will be sufficient)"
    ]
  },
  {
    question: "If the NFT metadata could be modified after creation?",
    answer: [
        "Once issuing an NFT collectible, NFT’s metadata could not be modified. Please review the NFT input content carefully before creation."
    ]
  },
  {
    question: "Whether merchant’s for-sell NFT list and redeemable NFT list are editable?",
    answer: [
      "Currently, both the for-sell NFT list and the redeemable NFT list are not editable. It is planned to support such feature in the next development phrase. So that merchants can control the list of NFT to display."
    ]
  },
  // More questions...
]
</script>