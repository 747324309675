<template>
  <div class="bg-white py-24 sm:py-32 lg:py-24" id="feature">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="sm:text-center">
        <h2 class="text-lg font-semibold leading-8 text-indigo-800">{{heading}}</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{subheading}}</p>
        <p class="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">{{description}}</p>
      </div>

      <div class="mt-20 max-w-lg sm:mx-auto md:max-w-none">
        <div class="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
            <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-800 text-white sm:shrink-0">
              <component :is="feature.icon" class="h-8 w-8" aria-hidden="true" />
            </div>
            <div class="sm:min-w-0 sm:flex-1">
              <p class="text-lg font-semibold leading-8 text-gray-900">{{ feature.name }}</p>
              <p class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ShoppingBagIcon, GlobeAltIcon, DocumentTextIcon, HeartIcon, CurrencyDollarIcon, UsersIcon } from '@heroicons/vue/24/outline'

const heading = "Web3 Toolkit for Commerce Brands"
const subheading = "Building web3 customer loyalty scheme in a simple way"
const description = ""

const features = [
  {
    name: 'Create Store Page',
    description:
        'Commercial brands could create their own store and launch their store page with web3 customer loyalty scheme features incorporated',
    icon: GlobeAltIcon,
  },
  {
    name: 'Stablecoin payment',
    description:
        'Customers pay stablecoin USDC to the commercial brands',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'NFT for sell',
    description:
        'Commercial brands issue NFTs for sell and customers purchase store’s NFTs with stablecoin USDC',
    icon: ShoppingBagIcon,
  },
  {

    name: 'NFT redeeming with rewardful score',
    description:
        'Commercial brands issue NFT for redeem with their store’s reward scores while customers earn reward scores when paying to the commercial brand.',
    icon: HeartIcon,
  },
  {
    name: 'DAO polling',
    description:
        'Commercial brands set up proposals to allow customers reaching certain spending requirements to vote for the proposal. Voting results are open to the public.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Support NFT trading',
    description:
        'All the NFTs being issues could be traded in NFT marketplaces like Opensea and Rarible',
    icon: UsersIcon,
  },
]
</script>