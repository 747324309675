<template>
  <div class="bg-gray-100">
    <div class="mx-auto max-w-7xl py-16 px-6 lg:px-8">
      <p class="text-center text-xl font-semibold text-gray-500">Backers and Partners</p>
      <div class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4">
        <div class="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-24" src="../assets/cyberport-logo.jpg" />
        </div>
        <div class="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-24" src="../assets/idendron-logo.png" />
        </div>
        <div class="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-24" src="../assets/polygon-logo.svg"/>
        </div>
        <div class="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
          <img class="h-24" src="../assets/the-graph-logo.jpg"/>
        </div>
<!--        <div class="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">-->
<!--          <img class="h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation" />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerSection"
}
</script>

<style scoped>

</style>